<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewMember"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-member', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Invite New Members</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!--          <validation-provider-->
          <!--            #default="validationContext"-->
          <!--            name="Email"-->
          <!--            rules="required"-->
          <!--          >-->
          <!--            <b-form-group-->
          <!--              label="Email"-->
          <!--              label-for="email"-->
          <!--            >-->
          <!--              <b-form-input-->
          <!--                id="email"-->
          <!--                v-model="userData.email"-->
          <!--                autofocus-->
          <!--                :state="getValidationState(validationContext)"-->
          <!--                trim-->
          <!--                placeholder=""-->
          <!--              />-->

          <!--              <b-form-invalid-feedback>-->
          <!--                {{ validationContext.errors[0] }}-->
          <!--              </b-form-invalid-feedback>-->
          <!--            </b-form-group>-->
          <!--          </validation-provider>-->
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="item.id"
            ref="row"
          >
            <!-- Item Name -->
            <b-col md="10">
              <validation-provider
                #default="validationContext"
                :name="`Email-${index + 1}`"
                rules="required|email"
              >
                <b-form-group
                  :label="`Email-${index + 1}`"
                  :label-for="`email-${index}`"
                >
                  <b-form-input
                    :id="`email-${index}`"
                    v-model="userData.email[index]"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Remove Button -->
            <b-col lg="2" md="4">
              <b-button
                @click="removeItem(index)"
                style="background-color: #404656 !important; border: #404656"
                class="btn-icon rounded-circle mt-0 mt-md-2"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>

            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="background-color: #404656 !important; border: #404656"
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Invite More</span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Invite All
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import { useToast } from "vue-toastification/composition";
import { heightTransition } from "@core/mixins/ui/transition";
export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [heightTransition],
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:add-new-member",
  },
  props: {
    isAddNewMember: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      items: [
        {
          id: 1,
        },
      ],
      nextTodoId: 2,
    };
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight);
      });
    },
  },
  setup(props, { emit }) {
    const blankuserData = {
      email: [],
    };

    const userData = ref(JSON.parse(JSON.stringify(blankuserData)));
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankuserData));
    };

    const toast = useToast();

    const onSubmit = () => {
      store
        .dispatch("Team/setNewMember", {
          emails: userData.value.email,
          id: store.state.Project.project.id,
        })
        .then(() => {
          emit("update:is-add-new-member", false);
          toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "You have successfully invited new members",
              icon: "CoffeeIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          console.log('err', err.response.data.emails)
          toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong",
              icon: "AlertTriangleIcon",
              variant: "danger",
              text: err.response.data?.emails
                ? err.response.data?.emails
                : "Please try again",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
